import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import PublicGiftSelector from "../social-supermarket/pages/gift-selection/PublicGiftSelector"

const Container = styled.div``

interface Props {}

const Slalom: FC<Props> = () => {
  return <PublicGiftSelector orderKey={"MzY0MTM4"} />
}

export default Slalom
